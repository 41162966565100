/**
 * Shareable utility methods/variables across throughout the code.
 */

import { utils } from "@zippin-js/core"

import { SEVERITY_LEVELS } from "../components/presentational/ZSnackBarComponent"
import { getEnvironment } from "./env"
import moment from "moment-timezone"
import { format } from "date-fns"

import type { constants as constantTypes } from "@zippin-js/core"

const isSuccessStatusCode = (statusCode: string): boolean => {
  const code = parseInt(statusCode)
  return code >= 200 && code < 300
}

const lightOrangeColorHex = "#FFEFE8"

const orangeColorVariantHex = "#FFA500"

const seaGreenColorHex = "#3CB371"

const darkOrangeColorVariantHex = "#b36200"

/**
 * Hex for two alternating colors; useful for contrasting table rows etc.
 */
const alternatingColorsHex = ["#FFFFFF", "#F2F2F2"]
const storeConfigAdminDomains = ["@juhomi.", "@getzippin.", "@mnw."]

const isStoreConfigAdmin = (email: string): boolean => {
  if (!storeConfigAdminDomains.some((domain) => email.includes(domain))) {
    return false
  } else {
    return true
  }
}

/*
  Some setup (such as tenant DB); exists only on GCP;
  so regardless of cloud, the GCP API must be called.
*/

const getStoreConfigGCPBaseUrl = () => {
  try {
    const environment: constantTypes.cloudEnvironments.CloudEnvironments = getEnvironment()
    const gcpOverride = true
    return utils.storeConfig.getBaseStoreConfigUrl(environment, gcpOverride)
  } catch (error) {
    console.error("Error getStoreConfigGCPBaseUrl:", error)
  }
}

type SnackBarProps = {
  show: boolean
  severity: SEVERITY_LEVELS
  message: string
}
const formattedDate2 = (date: number, timezone: string) => {
  return moment(date).tz(timezone).format("MM/DD/YYYY HH:mm A")
}
const showTimeZoneName = (date: number, timezone: string) => {
  const timezoneAbbreviation = moment.tz(date, timezone).format("z")
  return timezoneAbbreviation
}
const dateFormatter = (date: any) => {
  const dateFormat = moment(date).format("MMM-DD-YYYY")
  return dateFormat
}
const monthDiff = (d1: Date, d2: Date) => {
  let months
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months <= 0 ? 0 : months + 1
}
const numberFormatter = (num: number, symbol: string, currency: string) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  })
    .format(num)
    .replace(/^(\D+)/, `${symbol} `)
}
const labelNumberFormatter = (
  num: number,
  symbol: string,
  currency: string,
  isZippingBilling: boolean
) => {
  if (isZippingBilling === true) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    })
      .format(num)
      .replace(/^(\D+)/, `${symbol} `)
  } else {
    return String(num)
  }
}

const IsJsonString = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const formatSubmitDate = (date: Date) => {
  return moment(date).format("YYYY-MM-DD")
}
function dataFormat(data: number) {
  return new Intl.NumberFormat().format(data)
}

const getLoyaltyId = (memberId: string | Array<string>) => {
  let loyaltyId = ""
  if (memberId && Array.isArray(memberId) && memberId.length > 0) {
    const result = memberId.map((ele) => `*${ele.substr(ele.length - 4)}`)
    loyaltyId = result.join(",")
  } else {
    if (memberId && typeof memberId === "string") {
      loyaltyId = `*${memberId.substr(memberId.length - 4)}`
    }
  }
  return loyaltyId
}
const formatDate = (dateString: Date) => {
  if (dateString) {
    const date = new Date(dateString)
    const formattedDate = format(date, "MMM do, yyyy hh:mm:ss a")
    return formattedDate
  } else {
    return ""
  }
}

const ZIPPIN_SUPPORT_EMAIL = "support@getzippin.com"
export type { SnackBarProps }
export {
  isSuccessStatusCode,
  lightOrangeColorHex,
  orangeColorVariantHex,
  seaGreenColorHex,
  darkOrangeColorVariantHex,
  alternatingColorsHex,
  isStoreConfigAdmin,
  getStoreConfigGCPBaseUrl,
  showTimeZoneName,
  dateFormatter,
  monthDiff,
  numberFormatter,
  formatSubmitDate,
  dataFormat,
  labelNumberFormatter,
  formattedDate2,
  getLoyaltyId,
  IsJsonString,
  formatDate,
  ZIPPIN_SUPPORT_EMAIL,
}
